import { ElLoading, ElMessage } from 'element-plus'
import { nextTick } from 'vue'
import { IndexMessage } from '@/model/orderModel'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { useStore } from '@/store'
import router from '@/router'
export enum CODETYPE{
  LOGON_FAILURE = 10001,
  SUCCEED = 200
}
const store = useStore()
export interface RequestDataInter{
  code: number
  msg: string
  data: any
}
export interface RequestInter{
  requestMethodName: (option: IndexMessage<any> | undefined) => Promise<RequestDataInter>
  option?: IndexMessage<any>
  loadingTarget?: HTMLElement | null
}

const requestMiddle = async function({ requestMethodName, option, loadingTarget }: RequestInter) {
  let isloadding = true
  const loadingInstance = ElLoading.service({ target: loadingTarget || document.querySelector('.app-main') as HTMLElement })
  let data: null | any = null
  await requestMethodName(option).then((res) => {
    isloadding = false
    if (res?.code === CODETYPE.SUCCEED && res.data) {
      data = res.data
    } else if (res?.code === CODETYPE.LOGON_FAILURE) {
      ElMessage.error('登录失效，请重新登录！')
      store.dispatch(UserActionTypes.ACTION_LOGIN_OUT, undefined)
      router.push('/login?redirect=/').catch(err => {
        console.warn(err)
      })
    } else {
      res && ElMessage.error(res.msg)
    }

    !isloadding && nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
      loadingInstance.close()
    })
  }, (err: Error) => {
    ElMessage.error(err.message)
  })
  return data
}

export default requestMiddle
