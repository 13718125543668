
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  ref,
  nextTick,
  watch,
  PropType
} from 'vue'
import { exportJson2Excel } from '@/utils/excel'
import { formatJson } from '@/utils'
import { IndexMessage } from '@/model/orderModel'
import { useStore } from '@/store'
import { DeviceType } from '@/store/modules/app/state'
import { parseInt } from 'lodash'
interface DataInter{
  exportData: IndexMessage<string>[]
  title: string
  currentPage: number
  downloadLoading: boolean
  pageSize: number
}
export interface TableColumnItem{
  prop: string
  label: string
  width?: string
  minWidth?: string
  hascope?: boolean
  hasPop?: boolean
  popcontent?: string
}
export default defineComponent({
  name: 'OrderTable',
  emits: ['getList', 'columnClick', 'clearFilter'],
  props: {
    tableData: {
      type: [] as PropType<IndexMessage<string>[]>,
      default: () => {
        return []
      }
    },
    exportData: {
      type: [] as PropType<IndexMessage<string>[]>,
      default: () => {
        return []
      }
    },
    tableColumn: {
      type: [] as PropType<TableColumnItem[]>,
      default: () => {
        return []
      }
    },
    addFilter: {
      type: [] as PropType<string[]>,
      default: () => {
        return []
      }
    },
    listTotal: {
      type: Number,
      default: 0
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: 'serial', order: 'descending' }
      }
    }
  },
  setup(props, contex) {
    const store = useStore()
    const paginationRef = ref(null)
    const datePickerData = reactive({
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })()
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })()
      }],
      value: '',
      startTime: 0,
      endTime: 0,
      disabledDate(time: Date) {
        return time.getTime() > Date.now()
      }
    })
    const data: DataInter = reactive({
      exportData: props.exportData,
      title: '',
      currentPage: 1,
      pageSize: 10,
      downloadLoading: false
    })
    watch(() => props.exportData, (now) => {
      data.exportData = now
    })
    watch(() => datePickerData.value, (val) => {
      if (val) {
        datePickerData.startTime = parseInt(String(new Date(val[0]).getTime() / 1000))
        datePickerData.endTime = parseInt(String(new Date(val[1]).getTime() / 1000 + 86400)) // 让其可查询当天，所以多加一天的时间戳
      } else {
        datePickerData.startTime = datePickerData.endTime = 0
      }
    })

    const filterMethods = reactive({
      handleFilter: () => {
        contex.emit('getList', {
          start_time: datePickerData.startTime,
          end_time: datePickerData.endTime
        })
      },
      handleClear: () => {
        datePickerData.value = ''
        contex.emit('clearFilter')
        contex.emit('getList')
      },
      handleDownload: async() => {
        data.downloadLoading = true
        const tHeader: string[] = []
        const filterVal: string[] = []
        props.tableColumn.forEach(element => {
          tHeader.push(element.label)
          filterVal.push(element.prop)
        })
        await contex.emit('getList', {
          start_time: datePickerData.startTime,
          end_time: datePickerData.endTime,
          export: 1
        }, () => {
          nextTick(() => {
            if (data.exportData.length <= 0) {
              data.downloadLoading = false
              return
            }
            const formaData = formatJson(filterVal, data.exportData)
            exportJson2Excel(tHeader as string[], formaData, '麦苗订单列表')
            data.exportData = []
            data.downloadLoading = false
          })
        })
      },
      handleSizeChange(val: number) {
        contex.emit('getList', { size: val })
      },
      handleCurrentChange(val: number) {
        const page = data.currentPage
        if (!val || val === page) return
        contex.emit('getList', { page: val }, () => {
          data.currentPage = val
        }, () => {
          data.currentPage = page
          nextTick(() => {
            (paginationRef.value as any).internalCurrentPage = page
          })
        })
      }
    })
    const mobile = computed(() => {
      return store.state.app.device === DeviceType.Mobile
    })
    return {
      ...toRefs(data),
      ...toRefs(datePickerData),
      ...toRefs(filterMethods),
      mobile,
      paginationRef
    }
  }
})
