<template>
  <div
    class="account-container fami-container"
  >
    <template v-if="!isLoading">
      <FilterTable
        :tableColumn="tableColumn"
        :tableData="tableData"
        :listTotal="listTotal"
        :exportData="exportData"
        @clearFilter="handleClear"
        @getList="getAccountList"
        ref="filterTableRef"
        :defaultSort="{prop: 'flow_time', order: 'descending'}"
      >
        <div class="account-filter">
          <el-input
            v-model="title"
            placeholder="输入订单号"
            style="max-width: 300px"
            class="filter-item filter-item-title"
            @keyup.enter="handleFilter"
          />
          <el-input
            v-model="productName"
            placeholder="商品名称"
            style="max-width: 300px"
            class="filter-item filter-item-title"
            @keyup.enter="handleFilter"
          />
          <!-- <el-select
            v-model="selProId"
            placeholder="商品名称"
            class="filter-item filter-item-title"
          >
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select> -->
          <el-select
            v-model="selserial"
            placeholder="流水类型"
            class="filter-item filter-item-title"
          >
            <el-option
              v-for="item in option2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="seltrading"
            placeholder="交易平台"
            class="filter-item filter-item-title"
          >
            <el-option
              v-for="item in option3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </FilterTable>
    </template>
  </div>
</template>

<script lang='ts'>
import {
  computed,
  defineComponent, nextTick, reactive, ref, toRefs, onBeforeMount
} from 'vue'
import { useStore } from 'vuex'
import FilterTable, { TableColumnItem } from '@/components/filter-table/Index.vue'
import { IndexMessage, GetAccountList, AgentType } from '@/model/orderModel'
import requestMiddle, { RequestInter } from '@/utils/request'
import { initialFlow, accountFlow } from '@/apis/order'
interface DataInter{
  tableData: IndexMessage<string>[]
  exportData: IndexMessage<string>[]
  tableColumn: TableColumnItem[]
  isLoading: boolean
  listTotal: number
  title: string
  productName: string
  isfrist: boolean
  getAccountList: (option?: GetAccountList, callback?: () => void, failCb?: () => void) => void
}
export default defineComponent({
  components: {
    FilterTable
  },
  setup() {
    const store = useStore()
    const filterTableRef = ref(null)
    const agentType = computed(() => {
      return store.state.user.userinfo.agent_type
    })

    const options = reactive({
      option1: [],
      option2: [],
      option3: []
    })

    const selectVal = reactive({
      selProId: '',
      selserial: '',
      seltrading: ''
    })

    const nowMonth = computed(() => {
      return new Date().getMonth() + 1
    })

    const data: DataInter = reactive({
      tableColumn: [
        {
          prop: 'serial',
          label: '序号',
          width: '80'
        },
        {
          prop: 'flow_time',
          label: '添加时间',
          width: '200'
        },
        {
          prop: 'product_name',
          label: '商品名称',
          minWidth: '130'
        },
        {
          prop: 'transaction_id',
          label: '订单号',
          width: '250'
        },
        {
          prop: 'flow_category',
          label: '流水类型',
          width: '100'
        },
        {
          prop: 'platform',
          label: '交易平台',
          width: '100'
        },
        {
          prop: 't_status',
          label: '状态',
          width: '100'
        },
        // {
        //   prop: 'agent_name',
        //   label: '二级代理',
        //   width: '80'
        // },
        // {
        //   prop: 'is_auto',
        //   label: '缴费类型',
        //   width: '80'
        // },
        // {
        //   prop: 'buy_type',
        //   label: '购买类型',
        //   width: '80'
        // },
        // {
        //   prop: 'amount',
        //   label: '全额',
        //   width: '80'
        // },
        // {
        //   prop: 'rate',
        //   label: '手续费率',
        //   width: '80'
        // },
        // {
        //   prop: 'rate_fee',
        //   label: '手续费',
        //   width: '80'
        // },
        {
          prop: 'money',
          label: '净额',
          width: '80'
        },
        // {
        //   prop: 'commission_rate',
        //   label: '分成比例 ',
        //   width: '80'
        // },
        // {
        //   prop: 'has_commission',
        //   label: '佣金',
        //   width: '80'
        // },
        {
          prop: 'balance',
          label: '账户余额',
          width: '180',
          hasPop: true,
          popcontent: `${nowMonth.value}月订单未结算，不计入`
        }
      ],
      tableData: [],
      exportData: [],
      title: '',
      productName: '',
      listTotal: 0,
      isLoading: true,
      isfrist: true,
      getAccountList: async(option, successCb?, failCb?) => {
        if (data.isfrist) {
          try {
            const initia = await requestMiddle({
              requestMethodName: initialFlow
            } as RequestInter)
            data.isfrist = false
            const initiaKey = Object.keys(initia)
            initiaKey.forEach((key, i) => {
              options[`option${i + 1}`] = initia[key].map((item: IndexMessage<string | number>) => {
                item.value = item.id
                item.label = item.name
                return item
              })
            })
          } catch (error) {
            console.log(error)
          }
        }
        const newOption = {}
        nextTick(async() => {
          if (filterTableRef.value) {
            const { startTime, endTime, currentPage, pageSize } = filterTableRef.value as any
            const filterData = {
              start_time: startTime,
              end_time: endTime,
              page: currentPage,
              size: pageSize,
              transaction_id: data.title,
              product_name: data.productName,
              flow_category: selectVal.selserial,
              platform: selectVal.seltrading
            }
            Object.assign(newOption, filterData, option)
          }
          const dataType = option?.export === 1 ? 'exportData' : 'tableData'
          const requestData = await requestMiddle({
            requestMethodName: accountFlow,
            option: newOption
          } as RequestInter)
          try {
            let i = 1
            data[dataType] = requestData.data.map((item: IndexMessage<string | number>) => {
              item.serial = i++
              return item
            })
            data.listTotal = requestData.total
            successCb && successCb()
          } catch (error) {
            console.log(error)
            failCb && failCb()
          }
          data.isLoading = false
        })
      }
    })

    const method = reactive({
      handleClear: () => {
        // selectVal.selProId = ''
        data.productName = ''
        selectVal.selserial = ''
        selectVal.seltrading = ''
        data.title = ''
      },
      handleFilter: () => {
        data.getAccountList()
      }
    })

    onBeforeMount(() => {
      if (agentType.value === AgentType.DISTRIBUTOR) { // 一级代理分销商
        // data.tableColumn.forEach((item) => {
        //   if (item.prop === 'commission_rate') {
        //     item.label = '成本折扣价'
        //   }
        // })
      }
      data.getAccountList()
    })

    return {
      ...toRefs(options),
      ...toRefs(selectVal),
      ...toRefs(data),
      ...toRefs(method),
      filterTableRef
    }
  }
})
</script>

<style lang="scss" scoped>
.account-container{
  ::v-deep{
    .account-filter{
      .filter-item{
        margin-right: 10px;
      }
    }
    .filter-container .date-select{
      margin:10px 10px 10px 0;
    }
  }
}
@media (max-width: 767px){
  ::v-deep(.el-pagination--small){
    .el-pagination__total,.el-pagination__sizes,.el-pagination__jump{
      display: none;
    }
  }
}
@media (max-width: 575px){
 .filter-container .filter-item{
   width: 100%;
   max-width: 300px;
 }
}
</style>
