export interface IndexMessage<T>{
    [proppName: string]: T
}

export enum AgentType {
    DIVIDER = 1,
    DISTRIBUTOR = 2
}

export interface GetAccountList{
    size?: number
    page?: number
    search_key?: string
    start_time?: number
    end_time?: number
    export? : number
  }
