import { RootObject } from '@/model/rootObject'
import { IndexMessage } from '@/model/orderModel'
import https from '@/utils/https'
import { ContentType, Method, RequestParams } from 'axios-mapper'

export interface GetOrderList{
  size?: number
  page?: number
  search_key?: string
  start_time?: number
  end_time?: number
  export? : number
}

export interface CapitalWithdrawal{
  page?: number
  size?: number
}

export const requestIndexMessage = () => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/indexMessage', Method.POST, {}, ContentType.json)
}

export const withdrawal = () => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/capital/withdrawalPage', Method.POST, {}, ContentType.json)
}

export const requestOrderList = (option: GetOrderList) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/transaction', Method.POST, option, ContentType.json)
}

export const requestCapitalIndex = () => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/capital/index', Method.POST, {}, ContentType.json)
}

export const requestCapitalWithdrawal = (option: CapitalWithdrawal) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/capital/withdrawal', Method.POST, option, ContentType.json)
}

export const handleWithdrawal = (option: {money: string, db_url: string, oss: number}) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('/agent/capital/withdrawalApply', Method.POST, option, ContentType.json)
}

export const revokeWithdrawal = (option: {id: string | number}) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/capital/revokeWithdrawal', Method.POST, option, ContentType.json)
}

export const withdrawalDetail = (option: {id: string | number}) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/capital/withdrawalDetail', Method.POST, option, ContentType.json)
}

// 流水列表筛选条件数据
export const initialFlow = (option: RequestParams) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/initialFlow', Method.POST, option, ContentType.json)
}

// 获取账户流水列表
export const accountFlow = (option: RequestParams) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('agent/accountFlow', Method.POST, option, ContentType.json)
}
