<template>
  <div class="filter-container">
    <div class="btns-wrap">
      <slot />
      <el-date-picker
        v-model="value"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :shortcuts="shortcuts"
        class="date-select"
        :disabled-date="disabledDate"
        :editable="false"
      />
      <el-button
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        搜索
      </el-button>
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="handleClear"
      >
        清空
      </el-button>
      <el-button
        v-waves
        :loading="downloadLoading"
        class="filter-item filter-item-down"
        type="primary"
        icon="el-icon-download"
        @click="handleDownload"
      >
        导出
      </el-button>
    </div>
  </div>

  <el-table
    :data="tableData"
    style="width: 100%"
    :default-sort="defaultSort"
    ref="filterTable"
    border
    fit
    highlight-current-row
  >
    <el-table-column
      v-for="(item) in tableColumn"
      :key="item.prop"
      :prop="item.prop"
      :label="item.label"
      :width="(item.width ? item.width + 'px': '')"
      :min-width="(item.minWidth ? item.minWidth + 'px' : 0)"
      align="center"
    >
      <template
        #default="scope"
        v-if="item.hascope"
      >
        <el-button
          @click="$emit('columnClick', [item.prop, scope.row])"
          type="text"
          size="small"
        >
          {{ scope.row[item.prop] }}
        </el-button>
      </template>
      <!-- 表头有弹出 -->
      <template
        #header
        v-if="item.hasPop"
      >
        <span>{{ item.label }}</span>
        <el-popover
          placement="top-start"
          :width="200"
          trigger="hover"
          :content="item.popcontent"
        >
          <template #reference>
            <i
              class="el-icon-warning"
              style="font-size:16px;margin-left:10px;"
            />
          </template>
        </el-popover>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[5, 10, 15, 20]"
    v-model:page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="listTotal"
    :small="true"
    ref="paginationRef"
  />
</template>

<script lang='ts'>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  ref,
  nextTick,
  watch,
  PropType
} from 'vue'
import { exportJson2Excel } from '@/utils/excel'
import { formatJson } from '@/utils'
import { IndexMessage } from '@/model/orderModel'
import { useStore } from '@/store'
import { DeviceType } from '@/store/modules/app/state'
import { parseInt } from 'lodash'
interface DataInter{
  exportData: IndexMessage<string>[]
  title: string
  currentPage: number
  downloadLoading: boolean
  pageSize: number
}
export interface TableColumnItem{
  prop: string
  label: string
  width?: string
  minWidth?: string
  hascope?: boolean
  hasPop?: boolean
  popcontent?: string
}
export default defineComponent({
  name: 'OrderTable',
  emits: ['getList', 'columnClick', 'clearFilter'],
  props: {
    tableData: {
      type: [] as PropType<IndexMessage<string>[]>,
      default: () => {
        return []
      }
    },
    exportData: {
      type: [] as PropType<IndexMessage<string>[]>,
      default: () => {
        return []
      }
    },
    tableColumn: {
      type: [] as PropType<TableColumnItem[]>,
      default: () => {
        return []
      }
    },
    addFilter: {
      type: [] as PropType<string[]>,
      default: () => {
        return []
      }
    },
    listTotal: {
      type: Number,
      default: 0
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: 'serial', order: 'descending' }
      }
    }
  },
  setup(props, contex) {
    const store = useStore()
    const paginationRef = ref(null)
    const datePickerData = reactive({
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })()
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })()
      }],
      value: '',
      startTime: 0,
      endTime: 0,
      disabledDate(time: Date) {
        return time.getTime() > Date.now()
      }
    })
    const data: DataInter = reactive({
      exportData: props.exportData,
      title: '',
      currentPage: 1,
      pageSize: 10,
      downloadLoading: false
    })
    watch(() => props.exportData, (now) => {
      data.exportData = now
    })
    watch(() => datePickerData.value, (val) => {
      if (val) {
        datePickerData.startTime = parseInt(String(new Date(val[0]).getTime() / 1000))
        datePickerData.endTime = parseInt(String(new Date(val[1]).getTime() / 1000 + 86400)) // 让其可查询当天，所以多加一天的时间戳
      } else {
        datePickerData.startTime = datePickerData.endTime = 0
      }
    })

    const filterMethods = reactive({
      handleFilter: () => {
        contex.emit('getList', {
          start_time: datePickerData.startTime,
          end_time: datePickerData.endTime
        })
      },
      handleClear: () => {
        datePickerData.value = ''
        contex.emit('clearFilter')
        contex.emit('getList')
      },
      handleDownload: async() => {
        data.downloadLoading = true
        const tHeader: string[] = []
        const filterVal: string[] = []
        props.tableColumn.forEach(element => {
          tHeader.push(element.label)
          filterVal.push(element.prop)
        })
        await contex.emit('getList', {
          start_time: datePickerData.startTime,
          end_time: datePickerData.endTime,
          export: 1
        }, () => {
          nextTick(() => {
            if (data.exportData.length <= 0) {
              data.downloadLoading = false
              return
            }
            const formaData = formatJson(filterVal, data.exportData)
            exportJson2Excel(tHeader as string[], formaData, '麦苗订单列表')
            data.exportData = []
            data.downloadLoading = false
          })
        })
      },
      handleSizeChange(val: number) {
        contex.emit('getList', { size: val })
      },
      handleCurrentChange(val: number) {
        const page = data.currentPage
        if (!val || val === page) return
        contex.emit('getList', { page: val }, () => {
          data.currentPage = val
        }, () => {
          data.currentPage = page
          nextTick(() => {
            (paginationRef.value as any).internalCurrentPage = page
          })
        })
      }
    })
    const mobile = computed(() => {
      return store.state.app.device === DeviceType.Mobile
    })
    return {
      ...toRefs(data),
      ...toRefs(datePickerData),
      ...toRefs(filterMethods),
      mobile,
      paginationRef
    }
  }
})
</script>
<style lang="scss" scoped>
.el-table thead{
  width: 100%;
}
.btns-wrap{
  flex-shrink: 0;
  width: 100%;
}
.filter-container{
  display: flex;
  align-items: center;
  .filter-item{
    margin-bottom: 0 !important;
  }
  .date-select{
    width: 500px;
    margin:10px 10px 0 0;
  }
}

.el-pagination{
  display: flex;
  justify-content: flex-end;
  margin-top:50px;
}

.filter-container{
  display: flex;
  align-items: center;
  .filter-item{
    margin-bottom: 0;
  }
  ::v-deep(.date-select){
    width: 500px;
    margin:0 10px;
  }
}
@media (max-width: 1199px){
  .filter-container{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    ::v-deep(.date-select){
      margin:10px 0;
    }
    .btns-wrap{
      margin-bottom:20px;
    }
  }
}
@media (max-width: 575px){
  .order-container{
    ::v-deep(.tooltip) .text{
      transform: translate(-50% ,15px);
      top: 5px;
      left: 5px;
      &::after, &::before{
        transform: rotate(45deg) translateX(-50%);
        top: -8px;
        left: 50%;
        display: none;
      }
    }
  }
  .filter-container{
    ::v-deep(.date-select){
      width: 100%;
      .el-date-editor .el-range-separator{
        width: 24px;
      }
    }
  }
  ::v-deep(.el-picker-panel__sidebar){
    display: none;
  }

  .filter-item-title{
    max-width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 375px){
  .filter-container{
    .filter-item-down{
      margin:10px 0 0 0;
    }
  }
}
</style>
<style lang="scss">
.el-date-range-picker .el-picker-panel__content.is-right{
  display: none;
}
.el-picker-panel__sidebar+.el-picker-panel__body{
  min-width:260px ;
}
.el-date-range-picker.has-sidebar{
  width: 373px;
}
.el-date-range-picker__content{
  width: 100%;
}
@media (max-width: 767px){
  .el-picker-panel__sidebar{
    display: none;
  }
  .el-date-range-picker .el-picker-panel__body{
    margin-left: 0;
  }
  .el-date-range-picker__content{
    float:none;
  }
  .el-date-editor .el-range-separator{
    width: 25px;
  }
  .order-container{
    .el-pagination{
      .el-pagination__total,.el-pagination__sizes,.el-select,.el-pagination__jump{
        display: none;
      }
    }
  }
}

</style>
